import sass from "../scss/main.scss";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

// Плавное появление на первом экране
$(document).ready(function () {
  $(".intro__content").addClass("animation-text");
  $(".intro__wrap").addClass("animation-img");
  // Слайдер отзывов
  $(".testimonials__slider").owlCarousel({
    loop: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
        autoHeight: true
      },
      768: {
        items: 2
      }
    }
  });
});
